import Layout from '@/View/Admin/Layout/layout.vue'
export const Routing = { // dashboard object start
    path: '/',
    component: Layout,
    hidden: false,
    meta: {
        title: 'dashboards',
        icon: 'fas fa-border-all',
    },
    children: [
        {
            path: '/dashboard',
            component: () =>
                import ('@/View/Admin/dashboard/dashboard.vue'),
            name: 'Dashboard',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Dashboard',
                loadPage: 'Dashboard',
                permissionList: ['abc', 'def'],
                routeIcon: 'dashboardSvg',
                selected: false,
            }
        },
        {
            path: '/appUser',
            component: () =>
                import ('@/View/Admin/appUsers/listing.vue'),
            name: 'Users',
            meta: {
                icon: 'fas fa-border-all',
                title: 'App User',
                loadPage: 'Users',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'userSidebar',
            },
            children: [
            ]
        },
        {
            path: '/reportedShots',
            component: () =>
                import ('@/View/Admin/shorts/shortList.vue'),
            name: 'ReportedShorts',
            hidden: true,
            meta: {
                title: 'Reported Shots',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/reportedUser',
            component: () =>
                import ('@/View/Admin/reportedUser/userList.vue'),
            name: 'ReportedUsers',
            hidden: true,
            meta: {
                title: 'Reported User',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        }
    ]
}