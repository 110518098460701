<template>
  <div>
    <div id="myTopnav" class="topNav cust_card bg-primary2 p-1 hover:shadow-md items-center h-16" style="z-index: 10 !important;">
      <div class="heading-1 text-white font-semibold cursor-pointer text-center py-1 hover:text-gray-100 transition transform delay-50" @click="routingMain()" style="letter-spacing: 2px">CREAM</div>
        <div class="text-center right-3 absolute">
          <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-2 rounded-xl" @click.stop="LogOut()">
            <i class="fas fa-sign-out-alt"></i>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import {removeCookies} from '@/utils/cookies'
export default {
  components: {
  },
  name: "leftNavbar",
  props: ["routesList"],
  data() {
    return {
      isOpen: false,
      EmpObj: {},
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    let Isadmin =  JSON.parse(localStorage.getItem('empCInfo'))
    this.EmpObj = Isadmin
  },
  watch: {},
  methods: {
    LogOut () {
      removeCookies('empCAuthToken')
      localStorage.clear()
      this.$router.push({name: 'login'})
      location.reload()
    },
    routingMain () {
      this.$router.push({name: 'Dashboard'})
    },
    beforeDestroy() {
  },
  },
};
</script>
<style scoped>
.menu_iconss {
  width: inherit;
  padding: 0px 0px 0px 0px;
  display: inherit;
  align-items: center;
}
.row_user_svg {
  height: 25px;
  width: 25px;
}
</style>