<template>
  <div>
    <div class="flex">
      <div :class=" mobileView ? `main_container smv_main_container` : ``" class="bg-screen_bg">
        <div id="overlay"></div>
        <TopNavbar />
      </div>
    </div>
    <!-- style="height: 98vh !important;overflow: auto;" -->
    <div class="min-h-screen main_content_box bg-white p-2">
      <router-view />
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
  </div>
</template>
<script>
import TopNavbar from "@/View/Dashboard//components/topNavbar.vue";
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
export default {
  name: "landing-page",
  components: {
    TopNavbar,
    Loader,
    snakBar,
  },
  data() {
    return {
      userDetail: {},
      mobileView: false,
      extraSmall: false,
      routes: [],
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler();
    this.userDetail = JSON.parse(localStorage.getItem('empCInfo'))
    if (this.$route.path === '/dashboard' || this.$route.path === '/') {
      console.log('userDetail', this.$route.path)
      this.$router.push({name: 'Dashboard'})
    }
    window.scrollTo(0, 0);
    this.$store.dispatch("GetDevicePersistentRequest");
  },
  beforeDestroy() {
    this.$root.$off("drawercheckLOcked");
    this.$root.$off("favoriteTrue");
  },
  methods: {
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
  }
};
</script>
<style scoped>

</style>