
import Api from './API'
// import { get } from 'http'
// const crypto = require('crypto')

function AppModal () {
  if (!(this instanceof AppModal)) {
    return new AppModal()
  }
}

AppModal.prototype.loginToSpecificCompany = async function (
  emailId,
  password,
  callBackFunc,
  onError
) {
  let data = new FormData()
  data.append('emailId', emailId)
  data.append('password', password)
  Api.loginToSpecificCompany(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}


export default AppModal
